<template>
    <div class="">
        <div class="">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside search-result">
                        <div class="open-panel" hidden>
                            <i class="ti-angle-left"></i>
                        </div>
                        <div class="chat-left-inner form-horizontal">
                            <div class="form-material" style="">
                                <div class="input-group searchbox" v-if="!showConversation">
                                    <input style="border:none;" type="text" class="form-control" id="uname"
                                        @keyup.enter="searchContactFlag" placeholder="Cari Kontak" autocomplete="off"
                                        v-model="contactUiFlag.searchTerm">
                                    <div style="border:none;" class="input-group-addon">
                                        <a v-if="!showFilter" @click="showFilter = !showFilter">
                                            <v-icon>mdi-sort</v-icon>
                                        </a>
                                        <a v-if="showFilter" @click="resetCustomFilter">
                                            <v-icon>mdi-arrow-left</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div v-else>
                                    
                                    <a id="cancelSearch" class="btn m-2 text-muted" @click="cancelSearchPersonalMessage()">
                                        <v-icon class="">mdi-close</v-icon> <span class="ml-2 pt-1">Cari Pesan</span>  
                                    </a>
                                </div>
                            </div>
                            <div v-if="selectedSearchBy == 'contact' && !showFilter && !showConversation"
                                class="wrapp-filter-sort">
                                <b-badge class="sort-chat" pill v-if="pageQuery.sortOrder && pageQuery.sortDir">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon>
                                    {{ pageQuery.sortOrder == 'name' ? 'Pengirim' : pageQuery.sortOrder == 'lastActive' && pageQuery.sortDir == 'desc' ? 'Paling Baru' : 'Paling Lama'}}
                                </b-badge>
                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon>
                                    {{ selectedSearchBy }}
                                </b-badge>
                                <b-badge class="sort-chat" pill variant="primary" v-if="selectedDirection != ''">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon>
                                    {{ selectedDirection }} <a title="Hapus Filter"
                                        @click="removeSelected('', 'dirction', false)"><i class="mdi mdi-close"></i></a>
                                            <!-- <div class="open-panel">
                                        <i class="ti-angle-left"></i>
                                    </div> -->
                                </b-badge>
                                <b-badge class="sort-chat" pill variant="primary" v-if="selectedChannelContact != ''">
                                    <v-icon class="icon-sort-filter text-primary" left>
                                        {{ selectedChannelContact == 'WA' ? 'mdi-whatsapp' : 
                                            selectedChannelContact == 'FB' ? 'mdi-facebook-messenger' : 'mdi-instagram' }}
                                    </v-icon>
                                    {{ selectedChannelContact == 'WA' ? 'WhatsApp' : selectedChannelContact == 'FB' ? 'Facebook' : 'Instagram' }}
                                    <a title="Hapus Filter" @click="removeSelected('', 'channel', false)">
                                        <i class="mdi mdi-close"></i>
                                    </a>
                                </b-badge>
                                <b-badge class="tag-label badge badge-warning badge-pill" v-if="selectedStars">
                                    <span>
                                        <v-icon class="icon-sort-filter text-primary" left>mdi-star</v-icon> Ditandai
                                        <a title="Hapus Filter" @click="removeSelected('', 'stars', false)">
                                            <i class="mdi mdi-close"></i>
                                        </a>
                                    </span>
                                </b-badge>

                                <b-badge class="tag-label badge badge-primary badge-pill" pill
                                    v-for="(item, index) in selectedTags" :key="index">
                                    <span v-if="index < 2">
                                        <v-icon class="icon-sort-filter text-primary" left>mdi-tag</v-icon> {{ item }}
                                        <a title="Hapus Filter" @click="removeSelected(item, 'label', false)">
                                            <i class="mdi mdi-close"></i>
                                        </a>
                                    </span>
                                </b-badge>
                                <v-tooltip bottom v-if="selectedTags.length > 2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="badge tag-label badge-secondary badge-pill" v-bind="attrs"
                                            v-on="on">
                                            +{{ selectedTags.length - 2 }}
                                        </span>
                                    </template>
                                    <div v-for="(item, index) in selectedTags" :key="item">
                                        <span v-if="index > 1">
                                            <v-icon class="icon-sort-filter text-white" left>mdi-tag</v-icon> {{ item }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <b-badge class="badge cusRole badge-primary badge-pill " pill
                                    v-for="(item, index) in selectedRoles" :key="item">
                                    <span v-if="index < 2">
                                        <v-icon style="font-size:10px;  !important"
                                            class="icon-sort-filter text-primary" left>mdi-account-multiple</v-icon>
                                        {{ item }}
                                        <a title="Hapus Filter" @click="removeSelected(item, 'role', false)">
                                            <i class="mdi mdi-close"></i>
                                        </a>
                                    </span>
                                </b-badge>
                                <v-tooltip bottom v-if="selectedRoles.length > 2">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="cusRole badge badge-primary badge-pill " v-bind="attrs" v-on="on">
                                            +{{ selectedRoles.length - 2 }}
                                        </span>
                                    </template>
                                    <div v-for="(item, index) in selectedRoles" :key="item">
                                        <span v-if="index > 1">
                                            <v-icon class="icon-sort-filter text-white" left>mdi-account-multiple
                                            </v-icon> {{ item }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </div>
                            <div v-if="selectedSearchBy == 'messages' && !showFilter" class="wrapp-filter-sort">
                                <b-badge class="sort-chat" pill>
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-sort</v-icon>
                                    {{ contactUiFlag.sortBy == 'desc' ? 'Paling Baru' : 'Paling Lama' }}
                                </b-badge>

                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account</v-icon>
                                    {{ selectedSearchBy }}
                                </b-badge>

                                <b-badge pill class="sort-chat" v-if="customFilterUiFlag.kind == 'order'">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-cart</v-icon>
                                    No Pesanan / Pengembalian
                                </b-badge>
                            </div>
                            <div class="filter-sort-chat" v-if="showFilter">
                                <span class="filter">
                                    <h6 class="mt-0">Filter</h6>
                                    <b-button pill
                                        :variant="customFilterUiFlag.selectedSearchBy == 'contact' ? 'primary' : 'secondary'"
                                        class="mr-2" size="sm" @click="customFilterUiFlag.selectedSearchBy = 'contact'">
                                        <i class="mdi mdi-account"></i>
                                        Kontak</b-button>
                                    <b-button pill size="sm"
                                        :variant="customFilterUiFlag.selectedSearchBy == 'messages' ? 'primary' : 'secondary'"
                                        @click="customFilterUiFlag.selectedSearchBy = 'messages'">
                                        <i class="mdi mdi-message"></i>
                                        Pesan</b-button>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4">Pesan</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.lastMessagesDirection == '' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.lastMessagesDirection = ''">
                                            Semua Pesan
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.lastMessagesDirection == 'IN' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.lastMessagesDirection = 'IN'">
                                            Belum Balas
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.lastMessagesDirection == 'OUT' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.lastMessagesDirection = 'OUT'">
                                            Balas
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy != 'contact'">
                                        <h6 class="mt-4">Jenis</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.kind == 'text' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.kind = 'text'">
                                            Pesan
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.kind == 'order' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.kind = 'order'">
                                            No Pesanan / Pengembalian
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4">Kanal</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == '' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = ''">
                                            Semua
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == 'WA' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = 'WA'">
                                            WhatsApp
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == 'FB' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = 'FB'">
                                            Facebook
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.channel == 'IG' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.channel = 'IG'">
                                            Instagram
                                        </b-button>
                                    </div>
                                    <h6 class="mt-4">Urutkan</h6>
                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'desc' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'desc'">
                                            Paling Baru
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortOrder == 'lastActive' && customFilterUiFlag.sortDir == 'asc' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.sortOrder = 'lastActive', customFilterUiFlag.sortDir = 'asc'">
                                            Paling Lama
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortOrder == 'name' ? 'primary' : 'secondary'"
                                            class="mr-2"
                                            @click="customFilterUiFlag.sortOrder = 'name', customFilterUiFlag.sortDir = 'asc'">
                                            Pengirim
                                        </b-button>
                                    </div>
                                    <div v-if="customFilterUiFlag.selectedSearchBy != 'contact'">
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortMessage == 'desc' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.sortMessage = 'desc'">
                                            Paling Baru
                                        </b-button>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.sortMessage == 'asc' ? 'primary' : 'secondary'"
                                            class="mr-2" @click="customFilterUiFlag.sortMessage = 'asc'">
                                            Paling Lama
                                        </b-button>
                                    </div>

                                    <div v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <h6 class="mt-4">Kontak Ditandai</h6>
                                        <b-button pill size="sm"
                                            :variant="customFilterUiFlag.stars ? 'primary' : 'secondary'" class="mr-2"
                                            @click="customFilterUiFlag.stars = true">
                                            Ya
                                        </b-button>

                                        <b-button pill size="sm"
                                            :variant="!customFilterUiFlag.stars ? 'primary' : 'secondary'" class="mr-2"
                                            @click="customFilterUiFlag.stars = false">
                                            Tidak
                                        </b-button>
                                    </div>
                                    <div class="mt-6" v-if="customFilterUiFlag.selectedSearchBy == 'contact'">
                                        <v-autocomplete class="label-label mb-0" v-model="customFilterUiFlag.custroles"
                                            :items="customerRoleList" hide-selected multiple item-text="name"
                                            item-value="id" label="Peran Pelanggan" :loading="custRoleLoading">
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs" :input-value="data.selected" close
                                                    @click="data.select"
                                                    @click:close="removeSelected(data.item, 'role', true)">
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete class="label-label" v-model="customFilterUiFlag.labels"
                                            :items="items" hide-selected multiple persistent-hint chips label="Label">
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs" :input-value="data.selected" close
                                                    @click="data.select"
                                                    @click:close="removeSelected(data.item, 'label', true)">
                                                    {{ data.item }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </span>
                                <div class="clearfix"></div>
                                <div class="mt-4">
                                    <span class="pull-right">
                                        <b-button variant="secondary" class="mr-2" size="sm" @click="resetCustomFilter"
                                            style="border:1px solid rgb(237 237 237);">
                                            Batal
                                        </b-button>
                                        <b-button variant="primary" class="mr-2" size="sm" @click="customFilter">
                                            Simpan
                                        </b-button>
                                    </span>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="form-body" v-if="!$helpers.isBlank(contactUiFlag.error)">
                                <div class="form-material form-filter">
                                    <ErrorMessage :error="contactUiFlag.error" />
                                </div>
                            </div>
                            <div id="contact-loader-indicator" class="text-center" :class="{ 'd-none': !contactUiFlag.isLoading }">
                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                            </div>
                            <div v-if="!showConversation && !showSearchMessages && !showFilter && contactList.length > 0"
                                class="wrapp-filter-sort">
                                <v-btn rounded small class="mr-2" @click="showCloseChatMany = !showCloseChatMany">
                                    {{ !showCloseChatMany ? 'Tutup Percakapan Masal' : 'Batal' }}
                                </v-btn>
                                <v-btn rounded small class="btn-primary" v-if="showCloseChatMany"
                                    @click="closeChatMany">
                                    Tutup Percakapan
                                </v-btn>
                            </div>
                            
                            <ContactList
                                :page="'my-conversation'"
                                :contactList="contactList"
                                :searchMessageList="searchMessageList"
                                :msgUiFlag="msgUiFlag"
                                :contactUiFlag="contactUiFlag"
                                :showCloseChatMany="showCloseChatMany"
                                :showConversation="showConversation"
                                :showSearchMessages="showSearchMessages"
                                :showFilter="showFilter"
                                @jumpToElement="jumpToElement"
                                @selectCloseChatMany="selectCloseChatMany"
                                @selectContact="selectContact" />
                        </div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside layer-opacity">
                        <div class="layer-disable"></div>
                        <div class="chat-rbox">
                            <MessageHeader 
                                :page="'my-conversation'"
                                :msgUiFlag="msgUiFlag"
                                :contactUiFlag="contactUiFlag"
                                :showConversation="showConversation"
                                @editLabel="editLabel"
                                @deleteLabel="deleteLabel"
                                @searchPersonalMessage="searchPersonalMessage"
                                @saveContactCallback="saveContactCallback"
                                @copyStringCallback="copyStringCallback"
                            />

                            <MessageList
                                :page="'my-conversation'"
                                :msgUiFlag="msgUiFlag"
                                :messageList="messageList"
                                :isForward="isForward"
                                :selectedMessagesForward="selectedMessagesForward"
                                :emoticonList="emoticonList"
                                @setForward="setForward"
                                @quoteMessage="quoteMessage"
                                @jumpToElement="jumpToElement"
                                @removeMessage="removeMessage"
                                @openReaction="openReaction"
                                @reactMessage="reactMessage"
                                @openNote="openNote"
                                @deleteNote="deleteNote" />
                        </div>
                          
                        <div class="wrapp-input-text" v-if="contactUiFlag.selectedContactName">

                            <div class="quote-chat" v-if="quotedMessage != null">
                                <b class="text-info font-weight-bold">
                                    {{ quotedMessage.authorName }}
                                </b>
                                <br>
                                <div v-html="quotedMessage.text"></div>
                                <a href="#" title="Tutup" style="color:#838383; position:absolute; right:10px; top:10px"
                                    @click="cancelQuoteMessage">
                                    <i class="ti-close" style="font-size:16px;"></i>
                                </a>
                            </div>
                            <div class="clearfix"></div>
                            <div v-if="showReOpenBtn" class="btn-assign"
                                style="padding: 20px; margin: auto; text-align: center;">
                                <a data-toggle="modal" @click="confirmReopen" class="btn btn-primary">Chat
                                    Kembali</a>
                            </div>
                            <div v-if="loadingJump">
                                <div class="d-flex justify-content-center">
                                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                </div>
                            </div>
                            <div v-if="showTextArea && !isForward">
                                <div class="input-group group-text-input input-area-chat">
                                    <textarea style="height: 35px;" id="inputor" :disabled="newMessage.isLoading"
                                        v-model="newMessage.messageText" placeholder="Ketik pesan disini.."
                                        @paste="handlePaste" class="form-control b-0" @keyup.enter="handleEnterMessage"></textarea>
                                </div>
                            </div>
                            <div class="clearfix m-t-10"></div>
                            <b-dropdown no-caret size="xs" title="Pilih Kontak Utama"
                                v-if="contactUiFlag.selectedContactId != '' && !isForward" class="dropdown-contactNchannel" >
                                <template v-slot:button-content>
                                    <span>
                                        <img v-if="contactUiFlag.selectedContactDefaultChannel == 'WA'"
                                    :src="'images/wa-icon.png'" alt="WA" style="">
                                <img v-if="contactUiFlag.selectedContactDefaultChannel == 'SMS'"
                                    :src="'images/sms-icon.png'" alt="SMS" >
                                <img v-if="contactUiFlag.selectedContactDefaultChannel == 'FB'"
                                    :src="'images/fb-messenger-icon.png'" alt="FB">
                                <img v-if="contactUiFlag.selectedContactDefaultChannel == 'IG'" 
                                    :src="'images/instagram-icon.png'" alt="IG" />{{ contactUiFlag.selectedContactPrimaryNumber }}
                                    </span>
                                </template>
                                <b-dropdown-item v-for="item in contactUiFlag.selectedContactChannel"
                                    v-bind:key="item.id" href="#"
                                    @click="changeDestChannel($event, item.channel, item.number)">
                                    <img v-if="item.channel == 'WA'" :src=" 'images/wa-icon.png'" alt="WA"
                                        class="icon-channel" style="width:12px">
                                    <img v-if="item.channel == 'SMS'" :src=" 'images/sms-icon.png'" alt="SMS"
                                        class="icon-channel" style="width:12px">
                                    <img v-if="item.channel == 'FB'" :src=" 'images/fb-messenger-icon.png'" alt="FB"
                                        class="icon-channel" style="width:12px">
                                    <img v-if="item.channel == 'IG'" :src="'images/instagram-icon.png'" alt="IG" 
                                        class="icon-channel" style="width:12px" />
                                    {{ item.number }}
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-badge variant="light" class="m-r-10 font-weight-bold">
                                <img v-if="contactUiFlag.selectedContactDefaultChannel == 'WA'"
                                    :src=" 'images/wa-icon.png'" alt="WA" style="width:15px">
                                <img v-if="contactUiFlag.selectedContactDefaultChannel == 'SMS'"
                                    :src=" 'images/sms-icon.png'" alt="SMS" style="width:15px">
                                <img v-if="contactUiFlag.selectedContactDefaultChannel == 'FB'"
                                    :src=" 'images/fb-messenger-icon.png'" alt="FB" style="width:15px">
                                {{contactUiFlag.selectedContactPrimaryNumber}} </b-badge> -->
                            <b-dropdown no-caret v-if="contactUiFlag.selectedContactId != '' && !isForward" size="xs"
                                title="Pilih Saluran" class="dropup-select dropdown-contactNchannel">
                                <template v-slot:button-content>
                                    {{contactUiFlag.selectedSourceChannelName}}
                                </template>
                                <b-dropdown-item v-for="item in availableSourceChannel" v-bind:key="item._id" href="#"
                                    @click="changeSourceChannel($event, item)">
                                    <img class="icon-channel"
                                        v-if="item.channel_type == 'WABOXAPP' || item.channel_type == 'OPENWA' || item.channel_type == 'WACLOUDAPI' || item.channel_type == 'WABAILEYS'"
                                        :src=" 'images/wa-icon.png'" alt="WA" style="width:12px">
                                    <img v-if="item.channel_type == 'FBMSGR'" :src=" 'images/fb-messenger-icon.png'"
                                        alt="FB Messenger" class="icon-channel" style="width:12px">
                                    <img v-if="item.channel_type == 'INSTAGRAM'" :src="'images/instagram-icon.png'"
                                        alt="Instagram" class="icon-channel" style="width:12px">
                                    {{ item.name }}
                                    <span v-if="contactUiFlag.selectedSourceChannelName == item.name">
                                        <span class="mdi mdi-checkbox-marked-circle-outline ml-2 green-color"></span>
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <small class="text-danger pull-right mt-1" v-if="(!isLastMessageBeforeOneDay && 
                                (contactUiFlag.selectedSourceChannelType == 'FB' || contactUiFlag.selectedSourceChannelType == 'FBMSGR'))">
                                *Pesan masuk telah lebih dari 24 jam, silakan membalas pesan melalui sosmed Tuneeca*
                            </small>
                            <!-- <b-badge class="m-r-10 font-weight-bold" variant="light">
                                <b>{{contactUiFlag.selectedSourceChannelName}}</b></b-badge>
                            <b-badge variant="light" class="m-r-10 font-weight-bold">
                                <span v-if="contactUiFlag.isPaused">
                                    {{ contactUiFlag.pausedOnDisplay }}
                                </span>
                            </b-badge> -->

                            <div v-if="showTextArea && !isForward" class="m-t-5">

                                <div class="pull-left attach-list">

                                    <b-dropdown no-caret class="" title="Lampiran">
                                        <template #button-content>
                                            <i class="fa fa-paperclip"></i>
                                        </template>
                                        <b-dropdown-item @click="showUploadVideoDialog"
                                            v-if="contactUiFlag.selectedContactDefaultChannel == 'WA' || contactUiFlag.selectedContactDefaultChannel == 'FB' || 
                                            contactUiFlag.selectedContactDefaultChannel == 'IG'"
                                            title="Video">
                                            <span class="wrapp-icon-attach"> <i class="fa fa-video-camera"></i> </span>
                                            Video</b-dropdown-item>
                                        <b-dropdown-item @click="showUploadDocumentDialog"
                                            v-if="contactUiFlag.selectedContactDefaultChannel == 'WA' || contactUiFlag.selectedContactDefaultChannel == 'FB'"
                                            title="Dokumen">
                                            <span class="wrapp-icon-attach"> <i class="fa fa-file"></i></span> Dokumen
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="showUploadImageDialog"
                                            v-if="contactUiFlag.selectedContactDefaultChannel == 'WA' || contactUiFlag.selectedContactDefaultChannel == 'FB' || 
                                            contactUiFlag.selectedContactDefaultChannel == 'IG'"
                                            title="Gambar">
                                            <span class="wrapp-icon-attach"><i class="fa fa-image"></i></span> Gambar
                                        </b-dropdown-item>
                                    </b-dropdown>

                                    <DropdownProduct :channelId="contactUiFlag.selectedSourceChannelId"
                                        :channelDefault="contactUiFlag.selectedContactDefaultChannel"
                                        :contactId="contactUiFlag.selectedContactId"
                                        :contactNumber="contactUiFlag.selectedContactPrimaryNumber"
                                        @product-callback="productCallback" @product-callback-api="productCallbackApi"
                                        @send-info-product="sendMessageinfo" @copy-info-product="copyStringCallback" />

                                    <DropdownSalesOrder :parentId="contactUiFlag.selectedParentAccountId"
                                        :parentName="contactUiFlag.selectedParentAccountName"
                                        :contactId="contactUiFlag.selectedContactId" @send-info-order="sendMessageinfo"
                                        @copy-info-order="copyStringCallback" />

                                    <DropdownMessageTemplate :parentId="contactUiFlag.selectedParentAccountId"
                                        :contactId="contactUiFlag.selectedContactId"
                                        @send-info-message-template="sendMessageinfo"
                                        @copy-info-message-template="copyStringCallback"
                                        @send-login-link-commerce="sendMsgLinkCommerce" />

                                    <DropdownReturnOrder :parentId="contactUiFlag.selectedParentAccountId"
                                        :contactId="contactUiFlag.selectedContactId"
                                        @send-info-return-order="sendMessageinfo"
                                        @copy-info-return-order="copyStringCallback" />

                                    <b-dropdown no-caret title="Emoticon" class="dropup-select megaDropdown">
                                        <template v-slot:button-content>
                                            <i class="fa fa-smile-o"></i>
                                        </template>
                                        <div class="p-10">
                                            <b-tabs content-class="mt-3">
                                                <b-tab v-for="(item, index) in emoticonList" :key="index"
                                                    :title="item.icon">
                                                    <span class="cursor-pointer" v-for="(emoji, i) in item.emojis"
                                                        :key="i" @click="newMessage.messageText += emoji"
                                                        style="font-size: 20px;">
                                                        {{ emoji }}
                                                    </span>
                                                </b-tab>
                                            </b-tabs>
                                        </div>
                                    </b-dropdown>

                                    <DropdownAttachment :channelId="contactUiFlag.selectedSourceChannelId"
                                        :channelDefault="contactUiFlag.selectedContactDefaultChannel"
                                        :channelName="contactUiFlag.selectedSourceChannelName"
                                        :contactId="contactUiFlag.selectedContactId"
                                        :contactNumber="contactUiFlag.selectedContactPrimaryNumber"
                                        @attachment-callback="productCallback"
                                        @attachment-callback-api="productCallbackApi" />

                                    <!-- <b-dropdown no-caret class="" title="Pilih Kontak Utama"
                                        v-if="contactUiFlag.selectedContactId != ''"
                                        style="margin-right:10px; color: #5330c4">
                                        <template v-slot:button-content>
                                            <i class="fa fa-address-book"></i>
                                        </template>
                                        <b-dropdown-item v-for="item in contactUiFlag.selectedContactChannel"
                                            v-bind:key="item.id" href="#"
                                            @click="changeDestChannel($event, item.channel, item.number)">
                                            <img v-if="item.channel == 'WA'" :src=" 'images/wa-icon.png'" alt="WA"
                                                class="icon-channel">
                                            <img v-if="item.channel == 'SMS'" :src=" 'images/sms-icon.png'" alt="SMS"
                                                class="icon-channel">
                                            <img v-if="item.channel == 'FB'" :src=" 'images/fb-messenger-icon.png'"
                                                alt="FB" class="icon-channel">
                                            {{ item.number }}
                                        </b-dropdown-item>
                                    </b-dropdown> -->
                                    <!-- <b-dropdown no-caret v-if="contactUiFlag.selectedContactId != ''"
                                        title="Pilih Saluran" class="dropup-select" style="margin-right:10px;">
                                        <template v-slot:button-content>
                                            <i class="fa fa-cogs"></i>
                                        </template>
                                        <b-dropdown-item v-for="item in availableSourceChannel" v-bind:key="item._id"
                                            href="#" @click="changeSourceChannel($event, item)">
                                            <img class="icon-channel"
                                                v-if="item.channel_type == 'WABOXAPP' || item.channel_type == 'OPENWA' || item.channel_type == 'WACLOUDAPI' || item.channel_type == 'WABAILEYS'"
                                                :src=" 'images/wa-icon.png'" alt="WA">
                                            <img v-if="item.channel_type == 'FBMSGR'"
                                                :src=" 'images/fb-messenger-icon.png'" alt="FB Messenger"
                                                class="icon-channel">
                                            {{ item.name }}
                                            <span v-if="contactUiFlag.selectedSourceChannelName == item.name">
                                                <span class="mdi mdi-checkbox-marked-circle-outline ml-2"></span>
                                            </span>
                                        </b-dropdown-item>
                                    </b-dropdown> -->
                                    <button type="button" title="Pause Kontak" class="btn-secondary btn"
                                        v-if="!contactUiFlag.isPaused"
                                        @click="pauseResumeContact(true)">
                                        <i class="fa fa-pause"></i>
                                         <!-- <span class="mdi mdi-pause-circle"></span> -->
                                    </button>
                                    <button type="button" title="Mulai Kontak" class="btn-secondary btn" v-else
                                         @click="pauseResumeContact(false)">
                                        <!-- <span class="mdi mdi-play-circle"></span> -->
                                        <i class="fa fa-play" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="pull-right">
                                    <button class="btn btn-primary pull-right" @click="sendMessage($event)" title="Balas pesan"
                                        :disabled="newMessage.isLoading || (!isLastMessageBeforeOneDay && (contactUiFlag.selectedSourceChannelType == 'FB' || 
                                            contactUiFlag.selectedSourceChannelType == 'FBMSGR'))">
                                        <i v-if="newMessage.isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                        <i v-else class="fa fa-paper-plane"></i> 
                                        Balas
                                    </button>
                                    <button :disabled="contactUiFlag.isPaused"
                                        class="btn btn btn-default create-contact m-r-10 pull-right"
                                        @click="confirmClose">Selesai</button>
                                </div>
                            </div>
                            <div v-if="isForward" class="m-t-5">
                                <div class="pull-left attach-list">
                                    <v-btn icon large @click="isForward = !isForward, selectedMessagesForward = []">
                                        <v-icon style="font-size:24px !important" class="fa-2x" title="Tutup">mdi-close</v-icon>
                                    </v-btn>
                                </div>
                                <div class="pull-right">
                                    <v-btn class="text-white btn btn-primary mr-2" @click="openForward" :disabled="selectedMessagesForward.length < 1">
                                     Teruskan Pesan <v-icon class="m-l-10" title="Teruskan Pesan">mdi-share</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .chat-right-panel -->
                </div>
                <!-- /.chat-row -->
            </div>
        </div>
        
        <!-- <Label :contactId="contactUiFlag.selectedContactId" :myLabels="contactUiFlag.labels"
            @editlabel="updateSelectedLabel" /> -->

        <ForwardDialog ref="forward"
            :dialogForward="dialogForward"
            :selectedMessagesForward="selectedMessagesForward"
            :selectedSourceChannelId="contactUiFlag.selectedSourceChannelId"
            @closeDialog="closeDialogForward" />

        <VideoViewer v-bind:videoUrl="selectedVideo.videoUrl" v-bind:caption="selectedVideo.caption" />

        <SendVoice v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:channel="contactUiFlag.selectedContactDefaultChannel"
            v-bind:destNumber="contactUiFlag.selectedContactPrimaryNumber"
            v-bind:qmOriginId="quotedMessage ? quotedMessage.originId : null"
            v-bind:qmText="quotedMessage ? quotedMessage.text : null"
            v-bind:qmOriginExternalId="quotedMessage ? quotedMessage.originExternalId : null"
            v-bind:qmAuthorId="quotedMessage ? quotedMessage.authorId : null"
            v-bind:qmAuthorName="quotedMessage ? quotedMessage.authorName : null" @clearQuoted="cancelQuoteMessage"
            v-bind:sourceChannelId="contactUiFlag.selectedSourceChannelId" />

        <SendVideo v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:channel="contactUiFlag.selectedContactDefaultChannel"
            v-bind:destNumber="contactUiFlag.selectedContactPrimaryNumber"
            v-bind:qmOriginId="quotedMessage ? quotedMessage.originId : null"
            v-bind:qmText="quotedMessage ? quotedMessage.text : null"
            v-bind:qmOriginExternalId="quotedMessage ? quotedMessage.originExternalId : null"
            v-bind:qmAuthorId="quotedMessage ? quotedMessage.authorId : null"
            v-bind:qmAuthorName="quotedMessage ? quotedMessage.authorName : null" @clearQuoted="cancelQuoteMessage"
            v-bind:sourceChannelId="contactUiFlag.selectedSourceChannelId"
            @send-video-callback-api="sendMediaCallbackApi" />

        <SendDocument ref="senddocument" 
            v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:channel="contactUiFlag.selectedContactDefaultChannel"
            v-bind:destNumber="contactUiFlag.selectedContactPrimaryNumber"
            v-bind:qmOriginId="quotedMessage ? quotedMessage.originId : null"
            v-bind:qmText="quotedMessage ? quotedMessage.text : null"
            v-bind:qmOriginExternalId="quotedMessage ? quotedMessage.originExternalId : null"
            v-bind:qmAuthorId="quotedMessage ? quotedMessage.authorId : null"
            v-bind:qmAuthorName="quotedMessage ? quotedMessage.authorName : null" @clearQuoted="cancelQuoteMessage"
            v-bind:sourceChannelId="contactUiFlag.selectedSourceChannelId"
            @send-document-callback-api="sendMediaCallbackApi" />

        <SendImage ref="sendimage"
            v-bind:contactId="contactUiFlag.selectedContactId"
            v-bind:channel="contactUiFlag.selectedContactDefaultChannel"
            v-bind:destNumber="contactUiFlag.selectedContactPrimaryNumber"
            v-bind:qmOriginId="quotedMessage ? quotedMessage.originId : null"
            v-bind:qmText="quotedMessage ? quotedMessage.text : null"
            v-bind:qmOriginExternalId="quotedMessage ? quotedMessage.originExternalId : null"
            v-bind:qmAuthorId="quotedMessage ? quotedMessage.authorId : null"
            v-bind:qmAuthorName="quotedMessage ? quotedMessage.authorName : null" @clearQuoted="cancelQuoteMessage"
            v-bind:sourceChannelId="contactUiFlag.selectedSourceChannelId"
            @send-image-callback-api="sendMediaCallbackApi" />

        <UpdateNote v-bind:contactId="contactUiFlag.selectedContactId" v-bind:messageId="selectedMessage._id"
            :sessionId="contactUiFlag.selectedSessionId" v-bind:currentNote="selectedMessage.internalNotes"
            :showDialog="dialogNote" @close-dialog="closeDialog" />

        <Alert :message="alertMessage" />

        <CloseChat :showDialog="dialogCloseChat" :contactId="contactUiFlag.selectedContactId"
            :contactName="contactUiFlag.selectedContactName" :sessionId="contactUiFlag.selectedSessionId"
            :query="pageQuery" @close-dialog="closeDialog" @close-callback="closeChatCallback" />

        <ReopenChat :contactId="contactUiFlag.selectedContactId" :contactName="contactUiFlag.selectedContactName"
            @reopen-callback="reopenChatCallback" />
    </div>
</template>

<script>
    import Scrollbar from 'smooth-scrollbar';
    import axios from "axios";
    import MainLayout from "../layouts/MainLayout"
    import VideoViewer from '../components/VideoViewer'
    import SendVoice from '../components/SendVoice'
    import SendVideo from '../components/SendVideo'
    import SendDocument from '../components/SendDocument'
    import SendImage from '../components/SendImage'
    import ErrorMessage from '../components/ErrorMessage'
    import Alert from '../components/Alert'
    import CloseChat from '../components/CloseChat'
    import ReopenChat from '../components/ReopenChat'
    import UpdateNote from '../components/UpdateNote'

    import DropdownProduct from '../components/DropdownProduct.vue';
    import DropdownSalesOrder from '../components/DropdownSalesOrder.vue';
    import DropdownMessageTemplate from '../components/DropdownMessageTemplate.vue';
    import DropdownReturnOrder from '../components/DropdownReturnOrder.vue';
    import DropdownAttachment from '../components/DropdownAttachment.vue';
    import ForwardDialog from '../components/ForwardDialog.vue';

    import MessageList from '../components/MessageList.vue';
    import ContactList from '../components/ContactList.vue';
    import MessageHeader from '../components/MessageHeader.vue';

    // import Label from '../components/Label'
    import Toast from '../plugins/vuenotification'
    import EMOTICON from '../utils/emoticon'
    import {
        CHECK_TRANSFER_FALSE,
        CHECK_TRANSFER_TRUE,
        GREETING_CUSTOMER,
        HELP_CUSTOMER,
        INFO_CUSTOMER,
        NOTA_CUSTOMER,
        SHOPPING_FORMAT
    } from '../utils/templates/shopping';
    import IMAGE_ACC from '../../public/images/dummy.jpg'
    import IMAGE_MESSAGE from '../../public/images/image-not-found.png'

    import {
        mapState,
        mapActions
    } from 'vuex'

    const $ = require("jquery")
    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');
    const {
        updateContactList
    } = require('../utils/contact-utils');
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup');
    var firebase = require("firebase");
    var moment = require('moment');
    var debounce = require('lodash.debounce');
    var template = require('lodash.template');
    const uuidv4 = require('uuid/v4');
    const {
        MODULES
    } = require('../utils/const')
    const {
        phoneNumberEqual
    } = require('../utils/phonenumber-utils')

    const CONTACT_ROW_PER_PAGE = 25;
    const MESSAGE_ROW_PER_PAGE = 25;

    var messageScrollBar = null;
    var contactScrollBar = null;

    var messageListnerList = [];
    var removeContactListener = null;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                commerce: state => state.session.commerce,
                operatorList: state => state.operator.operatorList,
                items: state => state.select.labels,
                customerRoleList: state => state.select.customerRoles,
                isAccountTest: state => state.session.sessionInfo.userId == "bippofb"
            }),
        },
        data: function () {
            return {
                dialogCloseChat: false,
                dialogNote: false,
                dialogForward: false,
                showCloseChatMany: false,
                isForward: false,
                selectedCloseContact: Array(),
                emoticonList: Array(),
                isDropdown2Visible: false,
                selectedSearchBy: 'contact',
                checkChat: true,
                showFilter: false,
                showConversation: false,
                showSearchMessages: false,
                showQuote: false,
                custRoleLoading: false,
                selectedRoles: [],
                selectedLabel: [],
                selectedTags: [],
                selectedDirection: '',
                selectedStars: false,
                selectedChannelContact: '',
                contactList: [],
                messageList: [],
                selectedMessagesForward: [],
                searchMessageList: {
                    personal: [],
                    global: [],
                },
                pageQuery: {
                    status: 'open',
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    custroles: '',
                    labels: '',
                    lastMessagesDirection: '',
                    stars: false,
                    channel: ''
                },
                loadingJump: false,
                quotedMessage: null,
                contactUiFlag: {
                    isLoading: false,
                    selectedContactChannel: [],
                    currentPage: 1,
                    searchTerm: '',
                    labels: [],
                    selectedProfilePicUrl: '',
                    selectedContactId: '',
                    selectedContactName: '',
                    selectedContactRole: '',
                    selectedContactPrimaryNumber: '',
                    selectedContactDefaultChannel: '',
                    selectedContactPageId: '',
                    selectedIsUnknonwnContact: '',
                    selectedSourceChannelId: '',
                    selectedSourceChannelName: '',
                    selectedSourceChannelType: '',
                    selectedParentAccountId: '',
                    selectedParentAccountName: '',
                    selectedStars: false,
                    subAccountList: [],
                    hasMoreData: true,
                    selectedSessionId: '',
                    error: {},
                    sortBy: '',
                    isAffiliator: false,
                    currentAffiliator: null,
                    isPaused: false,
                    pausedOn: null,
                    pausedOnDisplay: String(),
                    kind: 'text'
                },
                searchLabel: null,
                newMessage: {
                    messageText: '',
                    isLoading: false
                },
                customFilterUiFlag: {
                    sortOrder: 'lastActive',
                    sortDir: 'desc',
                    labels: [],
                    custroles: [],
                    selectedSearchBy: 'contact',
                    sortMessage: 'desc',
                    lastMessagesDirection: '',
                    stars: false,
                    kind: 'text',
                    channel: '',
                },
                isSendInProgress: false,
                logErrorMsg: '',
                accountDetailUiFlag: {
                    isLoading: false,
                    data: Object(),
                    isLoadingOrder: false,
                    lastOrder: Array(),
                    isOk: false,
                    error: {},
                },
                msgUiFlag: {
                    isLoading: false,
                    currentPage: 1,
                    globalCurrentPage: 1,
                    hasMoreData: true,
                    hasMoreDataNext: true,
                    globalHasMoreData: true,
                    error: {},
                    searchTerm: ''
                },
                assignToData: {
                    operatorId: '',
                    operatorName: ''
                },
                newContact: {
                    id: '',
                    name: '',
                    saveErrorMessage: ''
                },
                isTyping: false,
                isTypingMsg: false,
                isTypingProduct: false,
                isTypingSalesOrder: false,
                isTypingReturn: false,
                isTypingReturnOrder: false,
                selectedVideo: {
                    imageUrl: '',
                    caption: ''
                },
                showButtonAdd: false,
                showTextArea: false,
                showReOpenBtn: false,
                onClickClose: false,
                onClickOpen: true,
                alertMessage: {},
                selectedMessage: {},
                availableSourceChannel: [],
                isLastMessageBeforeOneDay: true, 
            }
        },
        watch: {
            'contactUiFlag.sortBy': async function () {
                await this.searchGlobalMessage()
            },
            'newMessage.messageText': async function () {
                var self = this
                setTimeout(() => {
                    self.inputCollapse()
                }, 100)
            },
            'contactUiFlag.searchTerm': debounce(async function () {
                if (this.contactUiFlag.searchTerm == "") await this.loadContact()
            }, 1000),
            'msgUiFlag.searchTerm': debounce(function (value) {
                if (value == '') {
                    this.showConversation = false
                    return
                }
            }, 1000),
            'contactUiFlag.selectedContactDefaultChannel': function (val) {
                if (val && this.sessionInfo.activeChannels) {
                    if(this.isAccountTest) {
                        this.availableSourceChannel = this.sessionInfo.activeChannels.filter(channel => {
                            return ((channel.channel_type == 'WACLOUDAPI' && val == 'WA') || 
                                (channel.channel_type == 'FBMSGR' && val == 'FB') || 
                                (channel.channel_type == 'INSTAGRAM' && val == 'IG'))
                        })
                    } else {
                        this.availableSourceChannel = this.sessionInfo.activeChannels.filter(channel => {
                            return ((channel.channel_type == 'WABOXAPP' || channel.channel_type == 'OPENWA' ||
                                        channel.channel_type == 'WACLOUDAPI' || channel.channel_type ==
                                        'WABAILEYS') &&
                                    val == 'WA') ||
                                (channel.channel_type == 'FBMSGR' && val == 'FB') || (channel.channel_type == 'INSTAGRAM' && val == 'IG')
                        })
                    }
                } else {
                    this.availableSourceChannel = []
                }
                if (this.availableSourceChannel.length > 0) {
                    this.contactUiFlag.selectedSourceChannelId = this.availableSourceChannel[0]._id
                    this.contactUiFlag.selectedSourceChannelName = this.availableSourceChannel[0].name
                    this.contactUiFlag.selectedSourceChannelType = this.availableSourceChannel[0].channel_type
                } else {
                    this.contactUiFlag.selectedSourceChannelId = ''
                    this.contactUiFlag.selectedSourceChannelName = 'Channel Tidak Tersedia'
                    this.contactUiFlag.selectedSourceChannelType = ''
                }
            },
            selectedSearchBy: function (value) {
                this.showConversation = false
                if (value == 'messages') {
                    this.showSearchMessages = true
                } else {
                    this.showSearchMessages = false
                }
            },
            isTypingMsg: async function (value) {
                if (this.msgUiFlag.searchTerm == '') {
                    this.showConversation = false
                    return
                }
                if (!value) {
                    await this.searchPersonalMessage();
                }
            },
            $route: async function (to, from) {
                if (!to.query.chatId) {
                    this.msgUiFlag.searchTerm = ''
                    this.searchPersonalMsgList = []
                    this.showConversation = false,
                        this.selectedSearchBy = 'contact'
                }
                var refreshContact = false;

                if (to.query.labels != from.query.labels) {
                    refreshContact = true
                }

                if (to.query.custroles != from.query.custroles) {
                    refreshContact = true
                }

                if (to.query.lastMessagesDirection != from.query.lastMessagesDirection) {
                    refreshContact = true
                }

                if (to.query.status != from.query.status) {
                    this.pageQuery.status = to.query.status;

                    if (this.pageQuery.status == 'closed') {
                        this.showReOpenBtn = false;
                        this.showTextArea = false;
                        this.onClickClose = true;
                        this.onClickOpen = false;
                    } else {
                        this.showReOpenBtn = false;
                        this.showTextArea = false;
                        this.onClickClose = false;
                        this.onClickOpen = true;
                    }
                    refreshContact = true
                }

                if (to.query.sortOrder != from.query.sortOrder) {
                    this.pageQuery.sortOrder = to.query.sortOrder;
                    refreshContact = true
                }

                if (to.query.sortDir != from.query.sortDir) {
                    this.pageQuery.sortDir = to.query.sortDir;
                    refreshContact = true
                }

                const toStar = to.query.stars == true || to.query.stars == 'true' ? true : false
                const fromStar = from.query.stars == true || to.query.stars == 'true' ? true : false

                if (toStar != fromStar) {
                    this.pageQuery.stars = toStar
                    refreshContact = true
                }

                if (to.query.channel != from.query.channel) {
                    this.pageQuery.channel = to.query.channel;
                    refreshContact = true
                }

                if( to.query.status == 'open' && to.query.sortOrder == 'lastActive' && to.query.sortDir == 'desc' && 
                    this.$helpers.isBlank(to.params.id) && to.query.labels == '' && to.query.lastMessagesDirection == '' && 
                    !toStar ) {
                    refreshContact = true
                }

                if (refreshContact) {
                    this.msgUiFlag.currentPage = 1;
                    await this.loadContact();
                }

                if (this.$helpers.isBlank(to.params.id)) {
                    this.clearChatPanel();
                } else if ((to.params.id != from.params.id) || to.query.status != from.query.status) {
                    this.selectChat(decodeURIComponent(to.params.id))
                }

                const self = this
                if (to.query.chatId) {
                    setTimeout(async function () {
                        await self.jumpToElement(decodeURIComponent(to.query.chatId))
                    }, 1000)
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList',
                actGetCustomerRoles: 'select/actGetCustomerRoles',
                actGetCommentCount: 'getCommentCount'
            }),
            handlePaste: function (event) {
                const pastedText = event.clipboardData.getData('text/plain');

                if(!pastedText) {
                    
                    const items = event.clipboardData.items;
                    
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        if (item.type.indexOf("image") !== -1) {
                            const blob = item.getAsFile();
                            
                            if (blob) {
                                this.showUploadImageDialog(event)

                                const urlImage = URL.createObjectURL(blob);

                                this.$refs.sendimage.filesUpload = [blob]
                                this.$refs.sendimage.urlImage = [urlImage]
                            }
                        } else {
                            const blob = item.getAsFile();
                            
                            if (blob) {
                                this.showUploadDocumentDialog(event)
                                this.$refs.senddocument.file = blob
                            }

                        }
                    }
                }
            },
            closeDialog: function (val) {
                this.dialogNote = val
                this.dialogCloseChat = val
                this.selectedMessage = {}
            },
            closeDialogForward: function(kind) {
                if(kind == 'DONE') this.isForward = false
                this.dialogForward = false
            },
            copyStringCallback: function (str) {
                this.newMessage.messageText = str
            },
            productCallback: function (msgData) {
                this.loadingJump = true;

                if (msgData) {
                    var self = this;

                    this.messageList.splice(this.messageList.length, 0, msgData);

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                        messageScrollBar.addListener(self.messageScrollBarListener);
                    }, 50)
                }

                this.msgUiFlag.error = {}
            },
            productCallbackApi: async function (msgData, externalId, error) {
                if (error == null && this.contactUiFlag.isPaused) this.pauseResumeContact(false)

                if (msgData) {
                    var currentMsg = this.messageList[this.messageList.length - 1];
                    if (currentMsg._id == msgData._id && !currentMsg.externalId) {
                        currentMsg.externalId = externalId
                        this.messageList.splice(this.messageList.length - 1, 1, currentMsg);
                    }
                }

                if (error) {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    if (this.msgUiFlag.error.httpStatus == 424) this.newMessage.messageText = ''
                }

                window.scrollTo(0, document.body.scrollHeight)
                this.loadingJump = false;
            },
            sendMediaCallbackApi: async function (error) {
                if (error == null && this.contactUiFlag.isPaused) await this.pauseResumeContact(false)

                window.scrollTo(0, document.body.scrollHeight)
                this.loadingJump = false;
            },
            loopPausedTime: function () {
                var e = this

                e.updatePausedTime()

                var intervalId = setInterval(async function () {
                    // console.log(e.contactUiFlag.isPaused, e.contactUiFlag.selectedContactId, e.$route.params.id);
                    if (!e.contactUiFlag.selectedContactId || !e.contactUiFlag.isPaused || !e.$route
                        .params.id) {
                        clearInterval(intervalId);
                    } else {
                        e.updatePausedTime()
                    }
                }, 1000);
            },
            updatePausedTime: function () {
                const currentTime = moment() - moment(this.contactUiFlag.pausedOn);
                const hours = Math.floor(currentTime / 3600000);
                const minutes = Math.floor((currentTime % 3600000) / 60000);
                const seconds = Math.floor((currentTime % 60000) / 1000);

                this.contactUiFlag.pausedOnDisplay =
                    `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            },
            selectContact: function (item) {
                if (this.showCloseChatMany) {
                    document.getElementById(`check-${item.id}`).checked = !document.getElementById(
                        `check-${item.id}`).checked;

                    if (document.getElementById(`check-${item.id}`).checked) {
                        this.selectedCloseContact.push(item)
                    } else {
                        this.selectedCloseContact = this.selectedCloseContact.filter(e => e.id != item.id)
                    }
                } else {
                    this.$router.push({
                        name: 'myconversation',
                        params: {
                            id: encodeURIComponent(item.id)
                        },
                        query: {
                            ...this.pageQuery,
                        }
                    });
                }
            },
            closeChatMany: async function () {
                try {
                    this.showCloseChatMany = false

                    if (this.$route.params.id) {
                        messageScrollBar.removeListener(this.messageScrollBarListener);
                        this.messageList = [];
                        this.contactUiFlag.selectedContactName = '';
                        this.contactUiFlag.selectedContactPrimaryNumber = '';
                        this.contactUiFlag.selectedContactDefaultChannel = '';
                        this.contactUiFlag.selectedIsUnknonwnContact = '';
                        this.showReOpenBtn = false;
                        this.showTextArea = false;

                        this.$router.push({
                            name: 'myconversation',
                            query: {
                                ...this.pageQuery,
                            }
                        });
                    }

                    for (let index = 0; index < this.selectedCloseContact.length; index++) {
                        const element = this.selectedCloseContact[index];
                        await axios.put(process.env.VUE_APP_API_URL + '/contact/status/' + element.id, {
                            status: 'closed',
                            sessionId: element.sessionId
                        });
                    }

                } catch (error) {
                    console.log("bulk-close-chat", error);
                }
            },
            selectCloseChatMany: function (item, event) {
                event.preventDefault()

                if (document.getElementById(`check-${item.id}`).checked) {
                    this.selectedCloseContact.push(item)
                } else {
                    this.selectedCloseContact = this.selectedCloseContact.filter(e => e.id != item.id)
                }
            },
            pauseResumeContact: async function (paused) {
                try {
                    const body = {
                        isPaused: paused
                    }

                    await axios.put(process.env.VUE_APP_API_URL + '/contact/pause-resume/' + this.contactUiFlag
                        .selectedContactId, body)

                    document.getElementById("inputor").focus();
                } catch (error) {
                    console.log("update pause resume contact api", error)
                    Toast.showToast("error", error.response.data.message)
                }
            },
            editLabel: function (selectedLabels) {
                this.contactUiFlag.labels = selectedLabels
                this.contactList = this.contactList.map(e => {
                    if (e.id == this.contactUiFlag.selectedContactId) e.labels = selectedLabels
                    return e
                })
            },
            handleImageError: function (event, type) {
                if (type == 'contact') event.target.src = IMAGE_ACC;
                if (type == 'message') event.target.src = IMAGE_MESSAGE;
            },
            deleteNote: async function (note, messageId) {
                let deleted = {
                    ...note,
                    id: note._id,
                    sessionInfoId: this.contactUiFlag.selectedSessionId
                }

                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/message/' +
                        this.contactUiFlag.selectedContactId + '/' + messageId + '/removenote', deleted)
                } catch (error) {
                    console.log("delete note", error);
                }
            },
            download: function (text, url) {
                console.log(text, url);
                axios({
                        url: 'https://source.unsplash.com/random/500x500',
                        method: 'GET',
                        responseType: 'blob'
                    })
                    .then((response) => {
                        const urls = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')

                        link.href = urls
                        link.setAttribute('download', "image.jpg")
                        document.body.appendChild(link)
                        link.click()
                    })
            },
            searchContactFlag: async function () {
                if (this.selectedSearchBy == 'contact') {
                    this.showSearchMessages = false
                    this.loadContact();
                } else {
                    this.showSearchMessages = true
                    this.searchGlobalMessage();
                }
            },
            removeSelected: function (item, type, isFilter) {
                if (isFilter) {
                    if (type == 'role') {
                        const index = this.customFilterUiFlag.custroles.indexOf(item.id)
                        if (index >= 0) this.customFilterUiFlag.custroles.splice(index, 1)
                    } else {
                        const index = this.customFilterUiFlag.labels.indexOf(item)
                        if (index >= 0) this.customFilterUiFlag.labels.splice(index, 1)
                    }
                } else {
                    if (type == 'role') {
                        const index = this.selectedRoles.indexOf(item)
                        if (index >= 0) {
                            this.selectedRoles.splice(index, 1)
                            this.customFilterUiFlag.custroles = [...this.selectedRoles]
                            this.pageQuery = {
                                ...this.pageQuery,
                                custroles: this.selectedRoles.join(",")
                            }
                            localStorage.setItem('custroles', this.selectedRoles.join(","))
                        }
                    } else if (type == 'label') {
                        const index = this.selectedTags.indexOf(item)
                        this.selectedTags.splice(index, 1)
                        this.customFilterUiFlag.labels = [...this.selectedTags]
                        this.pageQuery = {
                            ...this.pageQuery,
                            labels: this.selectedTags.join(",")
                        }
                    } else if (type == 'stars') {
                        this.selectedStars = false
                        this.customFilterUiFlag.stars = false
                        this.pageQuery = {
                            ...this.pageQuery,
                            stars: false
                        }
                    } else if (type == 'channel') {
                        this.selectedChannelContact = ''
                        this.customFilterUiFlag.channel = ''
                        this.pageQuery = {
                            ...this.pageQuery,
                            channel: ''
                        }
                    } else {
                        this.selectedDirection = ''
                        this.customFilterUiFlag.lastMessagesDirection = ''
                        this.pageQuery = {
                            ...this.pageQuery,
                            lastMessagesDirection: ''
                        }
                    }
                    this.$router.push({
                        query: {
                            ...this.pageQuery
                        }
                    })
                }
            },
            resetCustomFilter: function () {
                this.customFilterUiFlag.sortDir = this.pageQuery.sortDir
                this.customFilterUiFlag.sortOrder = this.pageQuery.sortOrder
                this.customFilterUiFlag.selectedSearchBy = this.selectedSearchBy
                this.customFilterUiFlag.labels = [...this.selectedTags]
                this.customFilterUiFlag.custroles = [...this.selectedRoles]
                this.customFilterUiFlag.sortMessage = this.contactUiFlag.sortBy
                this.customFilterUiFlag.kind = this.contactUiFlag.kind
                this.customFilterUiFlag.searchTerm = this.contactUiFlag.searchTerm
                this.customFilterUiFlag.lastMessagesDirection = this.pageQuery.lastMessagesDirection
                this.customFilterUiFlag.stars = this.pageQuery.stars
                this.customFilterUiFlag.channel = this.pageQuery.channel
                this.showFilter = false
            },
            customFilter: async function () {
                this.showFilter = false
                let query = this.pageQuery
                let filter = this.customFilterUiFlag
                this.selectedSearchBy = filter.selectedSearchBy
                this.isTyping = true
                this.contactUiFlag.sortBy = filter.sortMessage
                this.contactUiFlag.kind = filter.kind
                if (filter.selectedSearchBy == 'contact') {
                    localStorage.setItem('custroles', filter.custroles.join(","))

                    if (
                        query.sortDir != filter.sortDir ||
                        query.sortOrder != filter.sortOrder ||
                        query.custroles != filter.custroles ||
                        query.labels != filter.labels ||
                        query.lastMessagesDirection != filter.lastMessagesDirection ||
                        query.stars != filter.stars ||
                        query.channel != filter.channel
                    ) {
                        this.pageQuery = {
                            ...query,
                            sortDir: filter.sortDir,
                            sortOrder: filter.sortOrder,
                            custroles: filter.custroles.join(","),
                            labels: filter.labels.join(","),
                            lastMessagesDirection: filter.lastMessagesDirection,
                            stars: filter.stars,
                            channel: filter.channel
                        }
                        this.selectedTags = [...filter.labels]
                        this.selectedRoles = [...filter.custroles]
                        this.selectedDirection = filter.lastMessagesDirection
                        this.selectedStars = filter.stars
                        this.selectedChannelContact = filter.channel
                        this.$router.push({
                            query: {
                                ...this.pageQuery
                            }
                        })
                    }

                    // await this.loadContact()
                } else {
                    await this.searchGlobalMessage()
                }
            },
            inputCollapse: function () {
                var inputNewMessage = document.getElementById("inputor");
                inputNewMessage.style.height = ""

                if (inputNewMessage.value.split("").indexOf("\n") == -1 || inputNewMessage.scrollHeight < 42)
                    inputNewMessage.style.height = "39px"
                if (inputNewMessage.scrollHeight > 41) inputNewMessage.style.height = Math.min(inputNewMessage
                    .scrollHeight, 300) + "px";

                $(window).trigger("collapse-message");
            },
            handleEnterMessage: async function (e) {
                console.log("e", e)
                if (e.ctrlKey || e.shiftKey || e.altKey) {
                    this.newMessage.messageText = this.newMessage.messageText + '\n'
                } else {
                    await this.sendMessage(e)
                }
            },
            deleteLabel: async function (deletedLabel) {
                this.contactUiFlag.labels = deletedLabel

                const index = this.contactList.findIndex(e => e.id == this.contactUiFlag.selectedContactId)
                this.contactList[index].labels = deletedLabel
            },
            sendMsgLinkCommerce: async function(isMobileApp) {
                const body = {
                    channelId: this.contactUiFlag.selectedSourceChannelId,
                    contactId: this.contactUiFlag.selectedContactId,
                    channel: this.contactUiFlag.selectedContactDefaultChannel,
                    number: this.contactUiFlag.selectedContactPrimaryNumber
                }

                await axios.post(process.env.VUE_APP_API_URL + '/message/login/link/commerce', body, {
                    params: {
                        isMobileApp: isMobileApp
                    }
                })
            },
            sendMessageinfo: async function (str, title, urlLink) {
                this.newMessage.isLoading = true
                const self = this
                var postData = {
                    "id": uuidv4(),
                    "channelId": this.contactUiFlag.selectedSourceChannelId,
                    "text": str,
                    "number": this.contactUiFlag.selectedContactPrimaryNumber
                };

                if (title == 'Link Produk') postData.url = this.commerce.webUrl + '/' + urlLink
                if (title == 'Video') {
                    const getEmbededVideoID = urlLink.split('/')[4]
                    postData.url = "https://www.youtube.com/watch?v=" + getEmbededVideoID
                }

                // if (this.contactUiFlag.selectedContactDefaultChannel == 'FB' || this.contactUiFlag.selectedContactDefaultChannel == 'IG') {
                //     postData.number = this.contactUiFlag.selectedContactPrimaryNumber
                // } else if (this.contactUiFlag.selectedContactDefaultChannel == 'SMS' || this.contactUiFlag
                //     .selectedContactDefaultChannel == 'WA') {
                //     postData.number = this.contactUiFlag.selectedContactPrimaryNumber
                // }

                this.msgUiFlag.error = {}

                const createdOn = (new Date()).getTime();
                var msgData = {
                    _id: postData.id,
                    channel: this.contactUiFlag.selectedContactDefaultChannel,
                    contactId: this.contactUiFlag.selectedContactId,
                    createdOn: createdOn,
                    contentType: 'text',
                    time: toFriendlyDateTime(createdOn),
                    from: 'op',
                    text: this.$helpers.formatTabMessages(postData.text),
                    sentTo: this.contactUiFlag.selectedContactPrimaryNumber,
                    status: 'pending',
                    operatorId: global.userId,
                    operatorName: global.userFullName,
                    channelName: this.contactUiFlag.selectedSourceChannelName
                }

                this.messageList.splice(this.messageList.length, 0, msgData);
                messageScrollBar.update();
                setTimeout(() => {
                    messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                    messageScrollBar.addListener(self.messageScrollBarListener);
                }, 50)

                try {
                    var postResponse = await axios.post(process.env.VUE_APP_API_URL + '/message/' + this
                        .contactUiFlag.selectedContactId + '/' + this.contactUiFlag
                        .selectedContactDefaultChannel, postData);

                    if (postResponse.data.externalId) {
                        var currentMsg = this.messageList[this.messageList.length - 1];
                        if (currentMsg._id == msgData._id && !currentMsg.externalId) {
                            currentMsg.externalId = postResponse.data.externalId
                            this.messageList.splice(this.messageList.length - 1, 1, currentMsg);
                        }
                    }

                } catch (error) {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    if (this.msgUiFlag.error.httpStatus == 424) {
                        this.newMessage.messageText = ''
                    }
                }

                this.quotedMessage = null
                this.newMessage.isLoading = false
                window.scrollTo(0, document.body.scrollHeight)
            },
            sendShoppingInfo: async function (kind, event) {
                event.preventDefault();

                try {
                    let str = String()

                    if (kind == 'format') str = template(SHOPPING_FORMAT.template)()
                    if (kind == 'check_bayar') str = template(CHECK_TRANSFER_FALSE.template)()
                    if (kind == 'check_sudah_bayar') str = template(CHECK_TRANSFER_TRUE.template)()
                    if (kind == 'membantu') str = template(HELP_CUSTOMER.template)()
                    if (kind == 'info') str = template(INFO_CUSTOMER.template)()
                    if (kind == 'nota') str = template(NOTA_CUSTOMER.template)()
                    if (kind == 'menyapa') {
                        const date = new Date()
                        str = template(GREETING_CUSTOMER.template)({
                            'jam': date.getHours()
                        })
                    }

                    await this.sendMessageinfo(str)

                } catch (error) {
                    console.log("shopping-info", error);
                }
            },
            jumpToElementGlobal: async function (id) {
                const contactId = this.contactUiFlag.selectedContactId
                this.loadingJump = true;
                this.msgUiFlag.hasMoreDataNext = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId + '/' + id + '?size=5');

                    this.messageList = this.listMessageMapper(data.content, this.messageList[0])

                    setTimeout(() => {
                        messageScrollBar.scrollIntoView(document.getElementById(`chatitem-${id}`));
                    }, 100);

                } catch (error) {
                    console.log(error);
                }

                this.loadingJump = false;
            },
            jumpToElement: async function (id) {
                const contactId = this.contactUiFlag.selectedContactId
                this.loadingJump = true;
                this.msgUiFlag.hasMoreDataNext = true

                try {
                    var isMessage = this.messageList.find(e => e._id == id || e.externalId == id)

                    if (!isMessage) {
                        const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId + '/' + id + '?size=5');

                        this.messageList = this.listMessageMapper(data.content, this.messageList[0])

                        await this.jumpToElement(id);
                    }

                    if (isMessage) {
                        setTimeout(() => {
                            messageScrollBar.scrollIntoView(document.getElementById(`chatitem-${id}`));
                        }, 100);
                    }

                } catch (error) {
                    console.log(error);
                }

                this.loadingJump = false;
            },
            reactMessage: async function(indx, message, text) {
                try {
                    const messageId = message.externalId || message._id
                    const body = {
                        "channelId": message.channelId,
                        "number": this.contactUiFlag.selectedContactPrimaryNumber,
                        "text": text
                    }

                    message.showReaction = false

                    this.messageList.splice(indx, 1, message)
    
                    await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactUiFlag.selectedContactId + '/' + 
                        messageId + '/reaction', body);
    
                    // if(res.status == 200) {
                    //     message.reactions.push({
                    //         "id": message.externalId,
                    //         "senderId": message.operatorId,
                    //         "senderName": message.operatorName,
                    //         "text": text,
                    //         "senderTimestamp": new Date(),
                    //         "_id": null
                    //     })
                    // }

                } catch (error) {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
            },
            quoteMessage: function (message) {
                this.quotedMessage = {
                    originId: message._id,
                    text: message.text,
                    originExternalId: message.externalId
                }
                if (message.from == 'op') {
                    this.quotedMessage.authorId = message.author ? message.author.contactId : message.operatorId
                    this.quotedMessage.authorName = message.author ? message.author.name : message.operatorName
                } else {
                    this.quotedMessage.authorId = message.author ? message.author.contactId : message.contactId
                    this.quotedMessage.authorName = message.author ? message.author.name : message.contactId
                }
                this.quotedMessage.contentType = message.contentType
                this.quotedMessage.text = message.text
                this.quotedMessage.url = message.url
                document.getElementById("inputor").focus();
            },
            cancelQuoteMessage: function () {
                this.quotedMessage = null
            },
            showUploadVideoDialog: function (event) {
                event.preventDefault()
                this.$bvModal.show('send-video-modal')
            },
            showUploadVoiceDialog: function (event) {
                event.preventDefault()
                this.$bvModal.show('send-voice-modal')
            },
            showUploadDocumentDialog: function (event) {
                event.preventDefault()
                this.$bvModal.show('send-document-modal')
            },
            showUploadImageDialog: function (event) {
                event.preventDefault()
                this.$bvModal.show('send-image-modal')
            },
            changeSourceChannel: async function (event, channel) {
                event.preventDefault();

                this.contactUiFlag.selectedSourceChannelId = channel._id
                this.contactUiFlag.selectedSourceChannelName = channel.name
                this.contactUiFlag.selectedSourceChannelType = channel.channel_type
            },
            changeDestChannel: async function (event, channel, number) {
                if(event) event.preventDefault();
                console.log("channel", channel, number)
                this.contactUiFlag.selectedContactPrimaryNumber = number
                this.contactUiFlag.selectedContactDefaultChannel = channel;
                this.contactUiFlag.error = {}

                var contactId = this.contactUiFlag.selectedContactId;
                const putData = {
                    "phoneNumber": number,
                    "channel": channel
                }

                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/contact/defaultchannel/' + contactId, putData);
                    var idx = this.contactList.findIndex((item) => {
                        return item.id == contactId;
                    })

                    if (idx >= 0) {
                        var data = this.contactList[idx];
                        data.primaryPhoneNumber = number;
                        data.defaultChannel = channel;
                        this.contactList.splice(idx, 1, data);
                    }
                } catch (error) {
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            sendMessage: async function (event) {
                event.preventDefault();

                if (!this.newMessage.messageText) throw new Error("Pesan tidak boleh kosong")

                var self = this;
                var postData = {
                    "id": uuidv4(),
                    "channelId": this.contactUiFlag.selectedSourceChannelId,
                    "text": this.newMessage.messageText,
                    "number": this.contactUiFlag.selectedContactPrimaryNumber
                };

                // if (this.contactUiFlag.selectedContactDefaultChannel == 'FB' || this.contactUiFlag.selectedContactDefaultChannel == 'IG') {
                //     postData.number = this.contactUiFlag.selectedContactPrimaryNumber
                // } else if (this.contactUiFlag.selectedContactDefaultChannel == 'SMS' || this.contactUiFlag
                //     .selectedContactDefaultChannel == 'WA') {
                //     postData.number = this.contactUiFlag.selectedContactPrimaryNumber
                // }

                this.msgUiFlag.error = {}

                const createdOn = (new Date()).getTime();

                let showOpHeader = false

                if(this.messageList.length > 0) {
                    const selectedMsg = this.messageList[this.messageList.length - 1]

                    if(selectedMsg.from == 'cust' || selectedMsg.operatorId != global.userId) {
                        showOpHeader = true
                    }
                }

                var msgData = {
                    _id: postData.id,
                    channel: this.contactUiFlag.selectedContactDefaultChannel,
                    contactId: this.contactUiFlag.selectedContactId,
                    createdOn: createdOn,
                    time: toFriendlyDateTime(createdOn),
                    from: 'op',
                    text: this.$helpers.formatTabMessages(this.newMessage.messageText),
                    sentTo: this.contactUiFlag.selectedContactPrimaryNumber,
                    status: 'pending',
                    operatorId: global.userId,
                    operatorName: global.userFullName,
                    showOpHeader: showOpHeader
                }

                if (this.quotedMessage) {
                    msgData.quotedMessage = this.quotedMessage
                    postData.quotedMessage = this.quotedMessage
                }

                this.messageList.splice(this.messageList.length, 0, msgData);
                messageScrollBar.update();
                setTimeout(() => {
                    messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                    messageScrollBar.addListener(self.messageScrollBarListener);
                }, 50)

                this.newMessage.isLoading = true
                try {
                    var postResponse = await axios.post(process.env.VUE_APP_API_URL + '/message/' + this
                        .contactUiFlag.selectedContactId + '/' + this.contactUiFlag
                        .selectedContactDefaultChannel, postData);

                    this.newMessage.messageText = ''
                    self.quotedMessage = null;
                    if (postResponse.data.externalId) {
                        var currentMsg = this.messageList[this.messageList.length - 1];
                        if (currentMsg._id == msgData._id && !currentMsg.externalId) {
                            currentMsg.externalId = postResponse.data.externalId
                            this.messageList.splice(this.messageList.length - 1, 1, currentMsg);
                        }
                    }

                } catch (error) {
                    // this.msgUiFlag.error = this.$helpers.createError(error)
                    // if (this.msgUiFlag.error.httpStatus == 424) {
                    //     this.newMessage.messageText = ''
                    // }
                    if(error.response) {
                        Toast.showToast("error", error.response.statusText)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
                this.quotedMessage = null
                this.newMessage.isLoading = false
                window.scrollTo(0, document.body.scrollHeight)
                setTimeout(() => {
                    document.getElementById("inputor").focus();
                }, 100)
            },
            removeMessage: async function (message) {
                var postData = {
                    "channelId": message.channelId,
                    "chatId": message.chatId,
                    "externalId": message.externalId
                };

                this.newMessage.isLoading = true
                try {
                    await axios.delete(process.env.VUE_APP_API_URL + '/message/openwa/' + message.externalId, {
                        data: postData
                    });

                    // const index = this.messageList.map(e => e.externalId).indexOf(message.externalId)

                    // this.messageList[index].status = 'removed'
                } catch (error) {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    if (this.msgUiFlag.error.httpStatus == 424) {
                        this.newMessage.messageText = ''
                    }
                }
                this.newMessage.isLoading = false
            },
            confirmReopen: function (event) {
                event.preventDefault();
                this.$bvModal.show("reopen-chat-dialog");
            },
            reopenChatCallback: async function (isSuccess, error) {
                if (isSuccess) {
                    this.$router.push({
                        query: {
                            ...this.pageQuery,
                            status: 'open'
                        }
                    })
                } else {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
            },
            confirmClose: function (event) {
                event.preventDefault();
                // this.$bvModal.show("close-chat-dialog");
                this.dialogCloseChat = true
            },
            openReaction(message) {
                this.messageList.forEach(e => {
                    e.showReaction = false
                })

                message.showReaction = !message.showReaction
            },
            openNote: function (message) {
                this.selectedMessage = message
                this.dialogNote = true
                // this.$bvModal.show("update-note-modal");
            },
            openForward() {
                this.dialogForward = true

                this.$refs.forward.getContacts()
            },
            setForward: function(item) {
                this.selectedMessagesForward = [item]
                this.isForward = true
            },
            closeChatCallback: async function (isSuccess, error) {
                if (isSuccess) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.messageList = [];
                    this.contactUiFlag.selectedContactName = '';
                    this.contactUiFlag.selectedContactPrimaryNumber = '';
                    this.contactUiFlag.selectedContactDefaultChannel = '';
                    this.contactUiFlag.selectedIsUnknonwnContact = '';
                    this.showReOpenBtn = false;
                    this.showTextArea = false;

                    this.$router.push({
                        name: 'myconversation',
                        query: {
                            ...this.pageQuery,
                        }
                    });

                    // this.loadContact()
                } else {
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
            },
            listSearchMessageMapper(messageList) {
                const mapped = messageList.map((val) => {
                    val.time = toFriendlyDateTime(val.createdOn);

                    return val;
                });

                return mapped
            },
            listMessageMapper(messageList, prevMsg) {
                const messageListReversed = messageList.reverse();
                
                const mapped = messageListReversed.map((val) => {
                    val.showReaction = false
                    val.time = toFriendlyDateTime(val.createdOn);

                    if (val.text) val.text = this.$helpers.formatTabMessages(val.text)

                    if (val.internalNotes) {
                        val.internalNotes.forEach(note => {
                            note.time = toFriendlyDateTime(note.createdOn)
                        })

                        val.internalNotes = this.formatInternalNotes(val.internalNotes)
                    }

                    if (val.isResponseTime && val.responseTime) {
                        if (val.responseTime < 60) {
                            val.convertedResponseTime = "0:" + val.responseTime
                        } else {
                            const minute = Math.floor(val.responseTime / 60)
                            const seconds = val.responseTime % 60
                            val.convertedResponseTime = minute + ":" + seconds
                        }
                    }

                    if (val.quotedMessage) {
                        val.quotedMessage.text = val.quotedMessage.text ? this.$helpers.formatTabMessages(val.quotedMessage.text) : ""
                    }

                    if (prevMsg != null && prevMsg.from == val.from && 
                        (prevMsg.from =='op' || prevMsg.from == 'op-wa') && 
                        prevMsg.operatorId == val.operatorId &&
                        moment(val.createdOn).subtract('seconds', 180).isBefore(prevMsg.createdOn)
                    ) {
                        val.showOpHeader = false;
                    } else if (prevMsg != null && prevMsg.from == 'cust' && 
                        prevMsg.from == val.from && 
                        moment(val.createdOn).subtract('seconds', 180000).isBefore(prevMsg.createdOn)
                    ) {
                        if (val.isGroup && prevMsg.author.contactId != val.author.contactId) {
                            val.showOpHeader = true;
                        } else {
                            val.showOpHeader = false;
                        }
                    } else {
                        val.showOpHeader = true;
                    }

                    prevMsg = val;

                    return val;
                });

                return mapped
            },
            formatInternalNotes: function (internalNotes) {
                const internalNotesComplain = internalNotes.filter(note => note.contentType == 'complain' || note
                    .contentType == 'agent' || note.contentType == 'product')

                let tempNotesWithoutComplain = []

                internalNotes.filter(note => note.contentType != 'complain' && note.contentType != 'agent' && note
                    .contentType != 'product').forEach(e => {
                    if (tempNotesWithoutComplain.length < 0) {
                        tempNotesWithoutComplain.push({
                            contentType: e.contentType,
                            authorId: e.authorId,
                            authorName: e.authorName,
                            list: [e]
                        })
                    } else {
                        const index = tempNotesWithoutComplain.findIndex(el => el.authorId == e.authorId &&
                            el.contentType == e.contentType)

                        if (index == -1) {
                            tempNotesWithoutComplain.push({
                                contentType: e.contentType,
                                authorId: e.authorId,
                                authorName: e.authorName,
                                list: [e]
                            })
                        } else {
                            tempNotesWithoutComplain[index].list.push(e)
                        }
                    }
                })

                return [...tempNotesWithoutComplain, ...internalNotesComplain]
            },
            loadMoreMessages: async function (isPrev) {
                let params = {
                    size: MESSAGE_ROW_PER_PAGE,
                    direction: isPrev ? 'before' : 'after',
                    timeStamp: isPrev ? this.messageList[0].createdOn : this.messageList[this.messageList
                        .length - 1].createdOn
                }

                var contactId = this.contactUiFlag.selectedContactId;
                this.msgUiFlag.error = {}

                if (this.$helpers.isBlank(contactId)) return;

                this.msgUiFlag.currentPage++;
                // var page = this.msgUiFlag.currentPage;
                if (!isPrev) this.loadingJump = true;

                this.msgUiFlag.isLoading = true;
                try {

                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/message/contact-ts-anchor/' + contactId, {
                        params
                    });

                    var jsonData = resp.data;

                    if (isPrev) {
                        this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    } else {
                        this.msgUiFlag.hasMoreDataNext = jsonData.page.number < jsonData.page.totalPages;
                    }
                    
                    const tmp = this.listMessageMapper(jsonData.content, this.messageList[0])

                    // before 
                    var scrollView = Scrollbar.get($('.wrapp-chat-content').get()[0]);
                    var currentHeight = scrollView.getSize().content.height;

                    if (isPrev) {
                        this.messageList.splice(0, 0, ...tmp);
                    } else {
                        this.messageList = [...this.messageList, ...tmp]
                    }

                    // wait for element tobe rendered, then call setPosition
                    scrollView.update();
                    setTimeout(() => {
                        if (isPrev) {
                            scrollView.setPosition(0, scrollView.getSize().content.height -
                                currentHeight, {
                                    withoutCallbacks: true
                                });
                        } else {
                            scrollView.setPosition(0, scrollView.offset.y);
                        }
                        messageScrollBar.addListener(this.messageScrollBarListener);
                    }, 120)
                } catch (error) {
                    messageScrollBar.addListener(this.messageScrollBarListener);
                    this.msgUiFlag.error = this.$helpers.createError(error)
                }
                this.msgUiFlag.isLoading = false;
                this.loadingJump = false;
            },
            loadMessage: async function (contactId) {
                this.msgUiFlag.isLoading = true;
                this.msgUiFlag.error = {}
                var self = this;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/message/contact/' + contactId, {
                        params: {
                            flagRead: true,
                            size: MESSAGE_ROW_PER_PAGE
                        }
                    });
                    this.msgUiFlag.isLoading = false;
                    var jsonData = resp.data;

                    let lastMessageFromCust = null

                    for (let i = 0; i < jsonData.content.length; i++) {
                        const e = jsonData.content[i];
                        
                        if(e.from == 'cust') {
                            lastMessageFromCust = e

                            break;
                        }
                    }

                    if(lastMessageFromCust) {
                        const end = moment()
                        const startTime = moment(lastMessageFromCust.createdOn)
    
                        var duration = moment.duration(end.diff(startTime));
                        var hours = duration.asHours();

                        if(hours < 24) {
                            this.isLastMessageBeforeOneDay = true
                        } else {
                            this.isLastMessageBeforeOneDay = false
                        }
                    }

                    this.showButtonAdd = true;
                    this.msgUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.msgUiFlag.hasMoreDataNext = false

                    const tmp = this.listMessageMapper(jsonData.content, null)

                    this.messageList = tmp

                    if (tmp.length > 0) {
                        const lastMsg = tmp[tmp.length - 1]
                        this.contactUiFlag.selectedSourceChannelId = lastMsg.channelId
                        this.contactUiFlag.selectedSourceChannelName = lastMsg.channelName
                        this.contactUiFlag.selectedSourceChannelType = lastMsg.channel

                        let lastMsgNum = null

                        if(lastMsg.author && lastMsg.author.phoneNumber) {
                            lastMsgNum = lastMsg.author.phoneNumber
                        } else if (lastMsg.sentTo) {
                            lastMsgNum = lastMsg.sentTo
                        }

                        if(lastMsgNum && !phoneNumberEqual(this.contactUiFlag.selectedContactPrimaryNumber, lastMsgNum, this.sessionInfo.defaultCountryCode)) {
                            const ch = this.contactUiFlag.selectedContactChannel.find(e => 
                                phoneNumberEqual(e.number, lastMsgNum, this.sessionInfo.defaultCountryCode)
                            )

                            if(ch) this.changeDestChannel(null, ch.channel, ch.number)
                        }
                    }

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height,
                            0);
                        messageScrollBar.addListener(self.messageScrollBarListener);
                        if (messageListnerList.indexOf(contactId) < 0) {
                            // to do fix bugs message 3 days ago
                            firebase.database().ref(self.sessionInfo.tenantId + '_' + process.env
                                .VUE_APP_ENV +
                                '/messages/' +
                                contactId).on("child_changed", self.updateMessageFromFb);
                            firebase.database().ref(self.sessionInfo.tenantId + '_' + process.env
                                .VUE_APP_ENV +
                                '/messages/' +
                                contactId).orderByChild("createdOn").limitToLast(1).on(
                                "child_added", self.updateMessageFromFb);
                            messageListnerList.push(contactId)
                        }
                    }, 250)

                    this.$bvModal.hide('modal-image-loader');
                } catch (error) {
                    this.msgUiFlag.isLoading = false;
                    messageScrollBar.addListener(this.messageScrollBarListener);
                    this.msgUiFlag.error = this.$helpers.createError(error)
                    this.$bvModal.hide('modal-image-loader');
                }
            },
            cancelSearchPersonalMessage: function() {
                this.showSearchMessages = false
                this.msgUiFlag.searchTerm = String()
                $(".open-panel").click();
            },
            searchPersonalMessage: async function () {
                const contactId = this.contactUiFlag.selectedContactId
                const term = this.msgUiFlag.searchTerm

                this.showSearchMessages = false
                this.showConversation = true
                this.resetCustomFilter()
                this.msgUiFlag.isLoading = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/search/' + contactId + '?term=' + term);

                    this.searchMessageList.personal = this.listSearchMessageMapper(data.content)

                } catch (err) {
                    console.log(err);
                }
                this.msgUiFlag.isLoading = false
            },
            searchGlobalMessage: async function () {
                const term = this.contactUiFlag.searchTerm
                const sort = this.contactUiFlag.sortBy
                const kind = this.contactUiFlag.kind

                this.contactUiFlag.isLoading = true
                this.msgUiFlag.globalCurrentPage = 1;
                this.msgUiFlag.globalHasMoreData = true;

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/message/search/mine', {
                        params: {
                            page: this.msgUiFlag.globalCurrentPage,
                            term: term,
                            size: CONTACT_ROW_PER_PAGE,
                            sortDir: sort,
                            kind: kind,
                        }
                    });

                    this.searchMessageList.global = this.listSearchMessageMapper(data.content)

                    const self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (err) {
                    contactScrollBar.addListener(this.contactScrollBarListener);
                    console.log(err);
                }
                this.contactUiFlag.isLoading = false
            },
            loadMoreGlobalMessage: async function () {
                const term = this.contactUiFlag.searchTerm
                const sort = this.contactUiFlag.sortBy

                this.contactUiFlag.isLoading = true;
                this.msgUiFlag.globalCurrentPage++;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/message/search/mine', {
                        params: {
                            page: this.msgUiFlag.globalCurrentPage,
                            term: term,
                            size: CONTACT_ROW_PER_PAGE,
                            sortDir: sort,
                        }
                    });

                    var jsonData = resp.data;
                    this.msgUiFlag.globalHasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    
                    const tmp = this.listSearchMessageMapper(jsonData.content)

                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

                    this.searchMessageList.global.splice(this.searchMessageList.global.length, 0, ...tmp);

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)

                } catch (error) {
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
                this.contactUiFlag.isLoading = false;
            },
            loadContact: async function () {
                this.contactList = [];
                this.contactUiFlag.error = {};

                this.showConversation = false
                this.contactUiFlag.currentPage = 1;
                this.contactUiFlag.hasMoreData = true;

                this.contactUiFlag.isLoading = true;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/assigned', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            sort: this.pageQuery.sortOrder,
                            term: this.customFilterUiFlag.selectedSearchBy == 'contact' ? this.contactUiFlag
                                .searchTerm : '',
                            status: this.pageQuery.status,
                            size: CONTACT_ROW_PER_PAGE,
                            labels: this.pageQuery.labels,
                            custroles: this.pageQuery.custroles,
                            sortDir: this.pageQuery.sortDir,
                            lastMessagesDirection: this.pageQuery.lastMessagesDirection,
                            stars: this.pageQuery.stars,
                            contactChannel: this.pageQuery.channel,
                        }
                    });
                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    var self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    contactScrollBar.addListener(this.contactScrollBarListener);
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreContacts: async function () {
                this.contactUiFlag.isLoading = true;
                this.contactUiFlag.error = {};
                this.contactUiFlag.currentPage++;

                try {
                    this.msgUiFlag.isLoading = true;
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/assigned', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            sort: this.pageQuery.sortOrder,
                            term: this.customFilterUiFlag.selectedSearchBy == 'contact' ? this.contactUiFlag
                                .searchTerm : '',
                            status: this.pageQuery.status,
                            size: CONTACT_ROW_PER_PAGE,
                            labels: this.pageQuery.labels,
                            custroles: this.pageQuery.custroles,
                            sortDir: this.pageQuery.sortDir,
                            lastMessagesDirection: this.pageQuery.lastMessagesDirection,
                            stars: this.pageQuery.stars,
                            contactChannel: this.pageQuery.channel,
                        }
                    });

                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    var tmp = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

                    this.contactList.splice(this.contactList.length, 0, ...tmp);

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)

                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
                this.contactUiFlag.isLoading = false;
            },
            updateContactListener: async function (snapshot) {
                if (this.contactUiFlag.selectedContactId == snapshot.key) {
                    const data = snapshot.val()

                    this.contactUiFlag = {
                        ...this.contactUiFlag,
                        selectedStars: data.stars,
                        isPaused: data.isPaused,
                        pausedOn: data.pausedOn,
                    }

                    if (data.isPaused) {
                        this.loopPausedTime()
                    } else {
                        this.contactUiFlag.pausedOnDisplay = String()
                    }
                }

                await updateContactList(
                    MODULES.MY_CONVERSATION, 
                    this.contactList, 
                    snapshot.val(), 
                    snapshot.key,
                    this.pageQuery.sortOrder,
                    this.contactUiFlag.searchTerm, 
                    !this.contactUiFlag.hasMoreData, 
                    this.sessionInfo.userId,
                    this.pageQuery.status, 
                    this.selectedTags, 
                    this.selectedRoles, 
                    this.pageQuery.lastMessagesDirection
                )
            },
            updateNotif: function () {
                var responseCode = '';
                var putData = {

                };
                fetch(process.env.VUE_APP_API_URL + '/notif/assigned', {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    credentials: 'include',
                    body: JSON.stringify(putData)
                }).then(function (response) {
                    responseCode = response.status;

                    // $(modalId).modal('hide');
                    return response.json();
                }).then(function () {
                    if (responseCode == 200) {
                        console.log('success update Notif');

                    } else {
                        console.log('error');
                    }
                }).catch(error => console.error('Error:', error));
            },
            updateMessageFromFb: function (snapshot) {
                var contactId = snapshot.ref.parent.key;
                var messageId = snapshot.key;
                var messageContent = snapshot.val();

                console.log("message from fb", messageContent);
                console.log("message from createdOn", messageContent._id);
                console.log("message from createdOn", messageContent.fromChannelId);

                const convertDate = messageContent.createdOn ? new Date(messageContent.createdOn) : new Date();
                messageContent.createdOn = convertDate.toISOString()
                messageContent.text = messageContent.text ? this.$helpers.formatTabMessages(messageContent.text) : ""

                messageContent.showReaction = false

                if (messageContent.internalNotes) {
                    messageContent.internalNotes.forEach(note => {
                        note.time = toFriendlyDateTime(note.createdOn)
                        note.createdOn = new Date(note.createdOn).toISOString(note.createdOn)
                        note.kind = note.kind ? note.kind : []
                    })
                    messageContent.internalNotes = this.formatInternalNotes(messageContent.internalNotes)
                }

                if (messageContent.isResponseTime) {
                    if (messageContent.responseTime < 60) {
                        messageContent.convertedResponseTime = "0:" + messageContent.responseTime
                    } else {
                        const minute = Math.floor(messageContent.responseTime / 60)
                        const seconds = messageContent.responseTime % 60
                        messageContent.convertedResponseTime = minute + ":" + seconds
                    }
                }

                let showOpHeader = false

                if(this.messageList.length > 0) {
                    const selectedMsg = this.messageList[this.messageList.length - 1]

                    if(selectedMsg.from == 'cust' || selectedMsg.operatorId != messageContent.userId) {
                        showOpHeader = true
                    }
                }

                var msgData = {
                    ...messageContent,
                    _id: messageId,
                    contactId: contactId,
                    time: toFriendlyDateTime(messageContent.createdOn),
                    showOpHeader: showOpHeader
                }
                /*var msgData = {
                    _id: messageId,
                    channel: messageContent.channel,
                    contactId: contactId,
                    createdOn: messageContent.createdOn,
                    time: toFriendlyDateTime(messageContent.createdOn),
                    from: messageContent.from,
                    text: messageContent.text
                }

                if (messageContent.url) {
                    msgData.url = messageContent.url;
                    msgData.thumb = messageContent.thumb;
                }

                if (msgData.from == 'op' || msgData.from == 'op-wa') {
                    msgData.sentTo = messageContent.sentTo;
                    msgData.status = messageContent.status;
                    msgData.operatorId = messageContent.operatorId;
                    msgData.operatorName = messageContent.operatorName;
                } else {
                    msgData.fromChannelId = messageContent.fromChannelId;
                    msgData.fromName = messageContent.fromName;
                }*/

                if (contactId == this.contactUiFlag.selectedContactId) {
                    if (this.messageList.length == 0) {
                        this.messageList.push(msgData);
                        messageScrollBar.update();
                        setTimeout(() => {
                            messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                            messageScrollBar.addListener(this.messageScrollBarListener);
                        })
                        // old data field createdOn is null
                    } else if (!this.messageList[0].createdOn || moment(this.messageList[0].createdOn)
                        .isSameOrBefore(messageContent.createdOn)) {
                        var i = 0;
                        var found = false;
                        do {
                            if (this.messageList[i]._id == messageId) {
                                this.messageList.splice(i, 1, msgData)
                                found = true;
                            } else if (moment(this.messageList[i].createdOn).isSameOrAfter(messageContent
                                    .createdOn)) {
                                this.messageList.splice(i, 0, msgData)
                                found = true;
                            }
                            i++;
                        } while (i < this.messageList.length && !found);

                        if (!found) {
                            this.messageList.push(msgData);
                        }

                        messageScrollBar.update();
                        const index = this.messageList.findIndex(e => e._id == msgData._id)

                        if (this.messageList.length == index + 1) {
                            setTimeout(() => {
                                messageScrollBar.scrollTo(0, messageScrollBar.getSize().content.height, 0);
                                messageScrollBar.addListener(this.messageScrollBarListener);
                            })
                        }

                    }
                } else {
                    var self = this;
                    firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV +
                        '/messages/' +
                        contactId).off(
                        "child_changed",
                        self.updateMessageFromFb);
                    firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV +
                            '/messages/' +
                            contactId)
                        .orderByChild(
                            "createdOn").limitToLast(1).off("child_added", self.updateMessageFromFb);
                    messageListnerList.splice(messageListnerList.indexOf(contactId), 1);
                }
            },
            messageScrollBarListener: function (status) {
                if (this.msgUiFlag.hasMoreData && status.offset.y == 0) {
                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreMessages(true);
                }
                if (this.msgUiFlag.hasMoreDataNext && status.limit.y == status.offset.y) {

                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreMessages(false);
                }
            },
            contactScrollBarListener: async function (status) {
                if (this.selectedSearchBy == 'contact') {
                    if (this.contactUiFlag.hasMoreData && status.limit.y == status.offset.y) {
                        const self = this
                        setTimeout(async () => await self.loadMoreContacts(), 300);
                    }
                } else {
                    if (this.msgUiFlag.globalHasMoreData && status.limit.y == status.offset.y) {
                        const self = this
                        setTimeout(async () => await self.loadMoreGlobalMessage(), 300);
                    }
                }
            },
            chatItemStyle: function (url, channel) {
                if (url) {
                    if (channel == 'cust') {
                        return 'box bg-light-inverse wrapp-chat-img';
                    } else {
                        return 'box bg-light-info wrapp-chat-img';
                    }
                } else {
                    if (channel == 'op') {
                        return 'box bg-light-info';
                    } else {
                        return 'box bg-light-inverse';
                    }
                }
            },
            viewVideo: function (messageData, event) {
                this.$bvModal.show("modal-video-preview");
                event.preventDefault();
                this.selectedVideo.videoUrl = messageData.url;
                this.selectedVideo.caption = messageData.text;
            },
            saveContactCallback: function (isSuccess, newContactId, data) {
                if (isSuccess) {
                    const index = this.contactList.findIndex(e => e.id == data.primaryPhoneNumber);

                    this.contactList[index] = {
                        ...this.contactList[index],
                        id: newContactId,
                        name: data.name,
                        primaryPhoneNumber: data.primaryPhoneNumber
                    }

                    this.$router.push({
                        params: {
                            id: encodeURIComponent(newContactId)
                        },
                        query: this.pageQuery
                    })
                }
            },
            clearChatPanel: function () {
                messageScrollBar.removeListener(this.messageScrollBarListener);
                this.messageList = [];
                this.contactUiFlag.selectedContactId = '';
                this.contactUiFlag.selectedContactName = '';
                this.contactUiFlag.selectedContactPrimaryNumber = '';
                this.contactUiFlag.selectedContactDefaultChannel = '';
                this.contactUiFlag.selectedIsUnknonwnContact = '';
            },
            selectChat: async function (contactId) {
                this.isForward = false
                messageScrollBar.removeListener(this.messageScrollBarListener);
                this.quotedMessage = null;
                if (this.onClickClose) {
                    this.showReOpenBtn = true;
                }
                if (this.onClickOpen) {
                    this.showTextArea = true;
                }

                const contact = this.contactList.find(e => e.id == contactId)
                this.salesOrderList = [];
                this.messageList = [];
                this.newMessage.messageText = String()
                this.contactUiFlag.selectedContactName = '';
                this.contactUiFlag.selectedSessionId = contact ? contact.sessionId : '';
                this.contactUiFlag.labels = contact ? contact.labels : []
                this.contactUiFlag.selectedProfilePicUrl = contact ? contact.profilePicUrl : ""
                this.contactUiFlag.selectedParentAccountId = contact ? contact.parentAccountId : ""
                this.contactUiFlag.selectedParentAccountName = contact ? contact.parentAccountName : ""
                this.contactUiFlag.subAccountList = Array()
                this.accountDetailUiFlag.data = Object()
                this.accountDetailUiFlag.lastOrder = Array()

                if (contact) {
                    this.contactUiFlag.selectedStars = contact.stars ? contact.stars : false
                    this.contactUiFlag.isAffiliator = contact.isAffiliator ? contact.isAffiliator : false
                    this.contactUiFlag.currentAffiliator = contact.currentAffiliator ? contact.currentAffiliator :
                        null
                    this.contactUiFlag.isPaused = contact.isPaused
                    this.contactUiFlag.pausedOn = contact.pausedOn

                    if (contact.isPaused) this.loopPausedTime()
                }

                this.contactUiFlag.selectedContactId = contactId;
                this.msgUiFlag.currentPage = 1;
                this.msgUiFlag.hasMoreData = true;

                var itemToUiFlag = function (item) {
                    var retval = item;
                    retval.psids = retval.psids || []

                    retval.selectedContactName = item.name || "NO NAME";
                    retval.selectedIsUnknonwnContact = item.unknownContact;
                    var tmp = item.phoneNumbers || [];
                    retval.selectedContactChannel = tmp.map(item => {
                        return {
                            channel: 'SMS',
                            number: item
                        }
                    });
                    tmp = item.waNumbers || [];
                    retval.selectedContactChannel.splice(0, 0, ...tmp.map(item => {
                        return {
                            channel: 'WA',
                            number: item
                        }
                    }));

                    retval.selectedContactChannel.splice(0, 0, ...retval.psids.map(psid => {
                        return {
                            channel: retval.defaultChannel,
                            number: psid.psid,
                            pageId: psid.pageId
                        }
                    }));
                    retval.selectedContactPrimaryNumber = item.primaryPhoneNumber == null ? retval
                        .selectedContactChannel[0].number : item.primaryPhoneNumber;
                    retval.selectedContactDefaultChannel = item.defaultChannel == null ? 'SMS' : item
                        .defaultChannel;

                    return retval;
                }

                if (contactId != null) {
                    var found = false;
                    this.contactList = this.contactList.map((item) => {
                        if (item.id == contactId) {
                            this.contactUiFlag.selectedContactName = item.name;
                            this.contactUiFlag.selectedContactRole = item.customerRole;
                            this.contactUiFlag.selectedIsUnknonwnContact = item.unknownContact;
                            this.contactUiFlag = {
                                ...this.contactUiFlag,
                                ...itemToUiFlag(item)
                            }
                            this.loadMessage(contactId);
                            item.selected = true;
                            found = true;
                        } else {
                            item.selected = false;
                        }
                        return item;
                    });

                    if (!found) {
                        this.msgUiFlag.error = {}
                        this.msgUiFlag.isLoading = true
                        try {
                            var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/idcommunicator/' +
                                contactId);
                            this.msgUiFlag.isLoading = false
                            var jsonData = resp.data;
                            if (jsonData.currentOperatorId != null) {
                                this.contactUiFlag.selectedContactName = jsonData.name;
                                this.contactUiFlag.selectedIsUnknonwnContact = jsonData
                                    .unknownContact;
                            }
                            this.contactUiFlag = {
                                ...this.contactUiFlag,
                                ...itemToUiFlag(jsonData)
                            }
                            this.loadMessage(contactId);
                        } catch (error) {
                            this.msgUiFlag.isLoading = false
                            this.msgUiFlag.error = this.$helpers.createError(error)
                        }
                    }
                }

                setTimeout(() => {
                    document.getElementById("inputor").focus();
                }, 100)
            },
            takeConversation: function (event) {
                this.$bvModal.show("take-conversation-dlg");
                event.preventDefault();
            },
            transferConversation: function (event, toUserId, toUserName) {
                this.$bvModal.show("transfer-conversation-dlg");
                event.preventDefault();

                this.assignToData.operatorId = toUserId;
                this.assignToData.operatorName = toUserName;
            },
            loadInitialData: async function () {
                removeContactListener = firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env
                        .VUE_APP_ENV +
                        '/contact/')
                    .on(
                        "child_removed",
                        function (oldChildSnapshot) {
                            this.contactList.splice(this.contactList.findIndex(contact => {
                                return contact.id === oldChildSnapshot.key;
                            }), 1);
                        });
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/')
                    .orderByChild(
                        "lastActive")
                    .limitToLast(1).on("child_added", this.updateContactListener);
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').on(
                    "child_changed",
                    this.updateContactListener);

                if (this.$route.query.status == null) {
                    this.pageQuery.status = 'open';
                } else {
                    this.pageQuery.status = this.$route.query.status;
                }

                if (this.$route.query.sortOrder) {
                    this.pageQuery.sortOrder = this.$route.query.sortOrder;
                    this.customFilterUiFlag.sortOrder = this.$route.query.sortOrder
                }

                if (this.$route.query.sortDir) {
                    this.pageQuery.sortDir = this.$route.query.sortDir;
                    this.customFilterUiFlag.sortDir = this.$route.query.sortDir
                }

                if (this.$route.query.labels) {
                    this.pageQuery.labels = this.$route.query.labels;
                    this.customFilterUiFlag.labels = this.$route.query.labels.split(',')
                    this.selectedTags = this.$route.query.labels.split(',')
                }

                if (this.$route.query.lastMessagesDirection) {
                    this.pageQuery.lastMessagesDirection = this.$route.query.lastMessagesDirection;
                    this.customFilterUiFlag.lastMessagesDirection = this.$route.query.lastMessagesDirection
                    this.selectedDirection = this.$route.query.lastMessagesDirection
                }

                if (this.$route.query.channel) {
                    this.pageQuery.channel = this.$route.query.channel;
                    this.customFilterUiFlag.channel = this.$route.query.channel
                    this.selectedChannelContact = this.$route.query.channel
                }

                if (this.$route.query.stars == 'true' || this.$route.query.stars == true) {
                    this.pageQuery.stars = true;
                    this.customFilterUiFlag.stars = true
                    this.selectedStars = true
                }

                var opListResp = this.initOperatorList()
                var contactResp = this.loadContact()

                await opListResp
                await contactResp

                if (this.$route.params.id != null) {
                    this.selectChat(decodeURIComponent(this.$route.params.id));
                }
            },
            formatCurrency(price) {
                let val = (price / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
        },
        //updated: function(){
        // document.getElementById("chatContent").style.height = (window.innerHeight - 275) + 'px'
        //},
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            if (localStorage.getItem('custroles')) {
                let custRoles = localStorage.getItem('custroles')
                this.selectedRoles = custRoles.split(",");
                this.customFilterUiFlag.custroles = custRoles.split(",");
                this.pageQuery = {
                    ...this.pageQuery,
                    custroles: custRoles
                }
                if (this.$route.query.custroles != custRoles) this.$router.push({
                    query: {
                        ...this.pageQuery
                    }
                })
            }

            this.$root.$on('bv::dropdown::show', bvEvent => {
                if (bvEvent.componentId === 'dropdown-11' || bvEvent.componentId === 'dropdown-12') {
                    this.isDropdown2Visible = true;
                }
            })
            this.$root.$on('bv::dropdown::hide', bvEvent => {
                if (bvEvent.componentId === 'dropdown-11' || bvEvent.componentId === 'dropdown-12') {
                    this.isDropdown2Visible = false;
                }
                if (this.isDropdown2Visible) {
                    bvEvent.preventDefault()
                }
            })

            Scrollbar.initAll();
            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);
            contactScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();

                this.actGetCommentCount();
            }
            try {
                mainuisetup();
                commonpagesetup("message", this.$route.params.id);
            } catch (err) {
                console.log(err);
            }
            
            await this.actGetCustomerRoles()

            this.emoticonList = EMOTICON
        },
        destroyed: function () {
            var self = this;
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').off(
                "child_removed",
                removeContactListener);
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/').off(
                "child_changed",
                this.updateContactListener);
            firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/contact/')
                .orderByChild(
                    "lastActive")
                .limitToLast(1).off("child_added", this.updateContactListener);
            for (var i = 0; i < messageListnerList.length; i++) {
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/messages/' +
                    messageListnerList[
                        i]).off(
                    "child_changed", self.updateMessageFromFb);
                firebase.database().ref(this.sessionInfo.tenantId + '_' + process.env.VUE_APP_ENV + '/messages/' +
                        messageListnerList[
                            i])
                    .orderByChild("createdOn").limitToLast(1).off("child_added", self.updateMessageFromFb);
            }
            messageListnerList = [];
        },
        components: {
            VideoViewer,
            SendVoice,
            SendVideo,
            SendDocument,
            SendImage,
            ErrorMessage,
            Alert,
            CloseChat,
            ReopenChat,
            UpdateNote,
            DropdownProduct,
            DropdownSalesOrder,
            DropdownMessageTemplate,
            DropdownReturnOrder,
            DropdownAttachment,
            ForwardDialog,
            MessageList,
            ContactList,
            MessageHeader
        }
    }
</script>